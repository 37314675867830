<template>
  <div class="tixian">
    <headbar :title="$t('映射')" :rightText="$t('账单记录')" bgColor="#080535"
      @rightClick="tolink('bill')"></headbar>

    <div class="content">
      <div class="title">{{ $t('映射币种') }}</div>
      <div class="input-wrap" @click="show = true">
        <div class="select-label">{{ select.coinname1 }} {{ $t('转') }} {{ select.coinname2 }}</div>
        <img src="@/assets/img/base/down.png" width="12" />
      </div>
      <div class="title">{{ $t('映射数量') }}</div>
      <div class="input-wrap">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div>
      <div class="balance">
        <div>{{ $t('账户余额') }}：{{ select.jine }}</div>
        <div class="all-btn" @click="num = select.jine">{{ $t('全部') }}</div>
      </div>
      <div class="btn" @click="submit">{{ $t('提交') }}</div>
    </div>

    <div class="cp-popup">
      <van-popup v-model:show="show" position="bottom">
        <van-picker :confirm-button-text="$t('确实')" :cancel-button-text="$t('取消')" :columns="selectList" @confirm="onConfirm"
          @cancel="show = false" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Picker, Popup } from 'vant';
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  setup() {
    return {
      cid: ref(""),
      us: ref(null),
      num: ref(""),
      select: ref({}),
      selectList: ref([]),
      show: ref(false)
    }
  },
  created() {
    this.cid = this.$route.query.cid
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getSelect()
    }
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    onConfirm(e) {
      this.select = e
      this.show = false
    },
    getSelect() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/ZhuanhuanSelect",
        {
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let zlist = res.data.data.zlist
          let wlist = res.data.data.wlist
          zlist.forEach(item => {
            let wallet = wlist.find(item2 => item.cid1 == item2.cid)
            item.text = item.coinname1 + _this.$i18n.t("转") + item.coinname2
            item.jine = wallet.jine
          })
          _this.selectList = zlist
          if (zlist.length > 0) {
            let select = zlist.find(item => item.cid == _this.cid)
            _this.select = select ? select : zlist[0]
          }
        }
      )
    },
    submit() {
      let _this = this
      if (!this.select) {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请选择货币"],
            persistent: true,
          }
        });
        return
      }
      if (this.num == "") {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要映射吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        this.$q.loading.show({
          message: _this.$i18n.t("映射中...")
        })
        _this.$request.post(
          "api/WalletsZhuanhuan/Zhuanhuan",
          {
            
            userid: _this.us.userid,
            zhid: _this.select.id,
            jine: _this.num
          },
          (res) => {
            _this.$q.loading.hide();
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            if (res.data.code == 100) {
              _this.num = ""
              _this.getSelect()
            }
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.tixian {
  padding: 10px;
}

.content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 0 15px 10px;
  background-color: #332d45;
}

.title {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #221c35;
}

.select-label {
  font-size: 18px;
  font-weight: bold;
  color: #e76efa;
}

.balance {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #aaaaaa;
}

.all-btn {
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #000;
  background-color: #e76efa;
}

.line {
  margin-top: 20px;
  height: 5px;
  background: url(~@/assets/img/base/fenge.png) no-repeat;
  background-size: 100% 100%;
}

.other-cell {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.btn {
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}

.cp-popup /deep/ .van-picker {
  background: #352D45 !important;
}

.cp-popup /deep/ .van-picker__mask {
  background-size: 100% 0 !important;
}

.cp-popup /deep/ .van-picker__frame::after {
  border-top: 2px solid #f56ef9;
  border-bottom: 2px solid #f56ef9;
}

.cp-popup /deep/ .van-picker-column__item {
  color: #fff;
}

.cp-popup /deep/ .van-picker__confirm {
  color: #f56ef9;
}

.cp-popup /deep/ .van-picker__title {
  color: #fff;
}
</style>